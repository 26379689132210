define("discourse/plugins/discourse-sort-by-topic-title/discourse/pre-initializers/extend-category-for-sort-by-title", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-category-for-sort-by-title",
    before: "inject-discourse-objects",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.30", api => {
        api.addCategorySortCriteria("title");
      });
    }
  };
});